import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { Navbar as NavbarBS } from 'react-bootstrap';
import "./navbar.css"
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Navbar() {
  const { t } = useTranslation();

  const serviceDetails_id = "65269adbbc7e86d175c91dfb"
  return (
    <NavbarBS expand="lg" >
      <Container>
        <NavbarBS>
          <Container>
            <Link to={"/"}>
              <img
                src={require('../../images/ekinoks1.jpg')}

                height="100"
                className="d-inline-block align-top"
                alt="Ekinoks Mekanik logo"
              />
            </Link>
          </Container>
        </NavbarBS>
        <NavbarBS.Toggle aria-controls="basic-navbar-nav" />
        <NavbarBS.Collapse className="justify-content-end">
          <Nav variant='underline'>
            <Link to="/" className='nav-link' >{t('home')}</Link>
            <Link to="/aboutus" className='nav-link' >{t('aboutUs')}</Link>
            <Link to={`/services/${serviceDetails_id}`} className='nav-link' >{t('services')}</Link>
            <Link to="/allprojects" className='nav-link'> {t('allProjects')}
              {/* <NavDropdown title={t('projects')} id="basic-nav-dropdown">
                <Link to="/completedProjects" className='dropdown-item'>{t('completedProjects')}</Link>
                <NavDropdown.Divider />
                <Link to="/goingonprojects" className='dropdown-item'>{t('goingOnProjects')}</Link>
              </NavDropdown> */}
            </Link>
            <Link to={`/media`} className='nav-link' >{t('media')}</Link>
            {/*     <Link to="/references" className='nav-link' >{t('references')}</Link> */}
            <Link to="/contactus" className='nav-link' >{t('contact')}</Link>
          </Nav>
        </NavbarBS.Collapse>
      </Container>
    </NavbarBS>
  );
}

export default Navbar;