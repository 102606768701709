import React from 'react'
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "../../pages/home/Home.jsx"
import ProjectPage from "../../pages/projectPage/ProjectPage.jsx";
import References from "../../pages/references/References.jsx";
import ServicesPage from "../../pages/servicesPages/ProjectControl.jsx";
import ContactUs from "../../pages/contact/Contact.jsx";
import Navbar from "../../components/navbar/Navbar.jsx"
import ContactAlert from "../../components/contactAlert/ContactAlert.jsx";
import Footer from "../../components/footer/Footer.jsx";
import { AnimatePresence, } from "framer-motion";
import AboutUs from '../../pages/aboutUs/AboutUs.jsx';
import PageNotFound from '../../pages/pageNotFound/PageNotFound.jsx';
import GoingOnProjects from '../../pages/goingOnProjects/GoingOnProjects.jsx';
import CompletedProjects from '../../pages/completedProjects/CompletedProjects.jsx';
import AllProjects from '../../pages/allProjects/AllProjects.jsx';
import Media from '../../pages/media/Media.jsx';
import ScrollToTop from '../../context/scrollToTop.js';


const AnimatedRoutes = () => {
    const location = useLocation();
    /*    const [displayLocation, setDisplayLocation] = useState(location);
       const [transitionStage, setTransistionStage] = useState("fadeIn");
   
       useEffect(() => {
           if (location.pathname.startsWith(`/services`)) { setTransistionStage("fadeIn"); setDisplayLocation(location); }
           else if (location !== displayLocation) setTransistionStage("fadeOut");
       }, [location, displayLocation]); */

    if (location.pathname.startsWith(`/admin`)) return

    return (
        <>
            <div className='cutterContainer'>
                <ContactAlert />
                <Navbar />
                <ScrollToTop />
                <AnimatePresence>
                    {location.pathname.startsWith(`/services`) ? (
                        <Routes location={location} key={location.pathname}>
                            <Route path="services/:serviceId" element={<ServicesPage location={location.pathname} />} />
                            <Route path="*" element={<PageNotFound />} />
                        </Routes>
                    ) : (
                        <div
                           /*  className={`${transitionStage}`}
                            onAnimationEnd={() => {
                                if (transitionStage === "fadeOut") {
                                    setTransistionStage("fadeIn");
                                    setDisplayLocation(location);
                                }
                            }}*/>
                            <Routes location={location} /* displayLocation */ key={location.pathname}>
                                <Route path="/" index element={<Home />} />
                                <Route path="allprojects" element={<AllProjects />} />
                                <Route path="goingonprojects" element={<GoingOnProjects />} />
                                <Route path="completedprojects" element={<CompletedProjects />} />
                                <Route path="projects/:projectId" element={<ProjectPage />} />
                                {/*                     <Route path="references/outdated/mightaddlater" element={<References />} />*/}
                                <Route path="aboutus" element={<AboutUs />} />
                                <Route path="contactus" element={<ContactUs />} />
                                <Route path="media" element={<Media />} />
                                <Route path="services/:serviceId" element={<ServicesPage location={location.pathname} />} />
                                <Route path="*" element={<PageNotFound />} />
                            </Routes>
                        </div>
                    )}
                </AnimatePresence>
            </div>
            <Footer />
        </>
    )
}

export default AnimatedRoutes