import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Button, Checkbox, Chip, FormControlLabel, ListItemButton, ListItemIcon, ListItemText, TextField } from '@mui/material';
import { DashboardSharp, Logout, PermMedia, PersonAdd, PersonAddAlt1, Send, Settings, Translate } from '@mui/icons-material';
import { useState } from 'react';
import { Table } from './adminComponents/Table';
import { useMutation, useQuery } from '@apollo/client';
import { GET_Aboutus, GET_CAROUSELS, GET_DETAILSERVICES, GET_FAVORITEPROJECTS, GET_FOOTER, GET_MEDIA, GET_PROJECTS_LIST, GET_REFERENCES } from '../../graphql/query';
import Loading from '../loading/Loading';
import { CREATE_CAROUSEL, CREATE_DETAIL_SERVİCE, CREATE_FAVORITE_PROJECT, CREATE_MEDIA, CREATE_PROJECT, DELETE_CAROUSEL, DELETE_DETAIL_SERVİCE, DELETE_FAVORITE_PROJECT, DELETE_MEDIA, DELETE_PROJECT, UPDATE_ABOUTUS, UPDATE_FOOTER } from '../../graphql/mutation';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { useEffect } from 'react';
import { AuthContext } from '../../context/authContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'©Ekinoks '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard() {
    const { loading, error, data, refetch } = useQuery(GET_PROJECTS_LIST);
    const { loading: loading2, error: error2, data: data2, refetch: rf2 } = useQuery(GET_CAROUSELS);
    const { loading: loading3, error: error3, data: data3, refetch: rf3 } = useQuery(GET_DETAILSERVICES);
    const { loading: loading4, error: error4, data: data4, refetch: rf4 } = useQuery(GET_FOOTER);
    const { loading: loading5, error: error5, data: data5, refetch: rf5 } = useQuery(GET_FAVORITEPROJECTS);
    const { loading: loading6, error: error6, data: data6, refetch: rf6 } = useQuery(GET_Aboutus);
    const { loading: loadingMedia, error: mediaError, data: mediaData, refetch: rf7 } = useQuery(GET_MEDIA);
/*     const { loading: loading2, error: error2, data: data2 } = useQuery(GET_REFERENCES);
 */    const [deleteProject] = useMutation(DELETE_PROJECT);
    const [deleteCarousel] = useMutation(DELETE_CAROUSEL);
    const [createProject, { loading: createLoading }] = useMutation(CREATE_PROJECT);
    const [createCarousel, { loading: loadingCarousel }] = useMutation(CREATE_CAROUSEL);
    const [updateAboutus, { loading: loadingAboutus }] = useMutation(UPDATE_ABOUTUS);
    const [updateFooter, { loading: loadingFooter }] = useMutation(UPDATE_FOOTER);
    const [createDetailService, { loading: loadingServices }] = useMutation(CREATE_DETAIL_SERVİCE);
    const [deleteDetailService] = useMutation(DELETE_DETAIL_SERVİCE);
    const { logout } = useContext(AuthContext)
    const navigate = useNavigate();
    useEffect(() => {
        return
    }, [rf2(), rf5(), deleteCarousel, data5])


    const [place, setPlace] = useState("main");
    const [open, setOpen] = useState(true);
    const [aboutUs, setAboutUs] = useState("");
    const [aboutUsEn, setAboutUsEn] = useState("");
    const [aboutUsRu, setAboutUsRu] = useState("");
    const [ourVision, setOurVision] = useState("");
    const [ourVisionEn, setOurVisionEn] = useState("");
    const [ourVisionRu, setOurVisionRu] = useState("");
    const [values, setValues] = useState({ status: false });
    const [imageUrl, setImageUrl] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);
    const [mediaTitle, setMediaTitle] = useState("");
    const [mediaImageUrl, setMediaImageUrl] = useState(null);
    const [mediaImageUrls, setMediaImageUrls] = useState([]);
    const [carouselCaption, setCarouselCaption] = useState("");
    const [carouselCaptionEn, setCarouselCaptionEn] = useState("");
    const [carouselCaptionRu, setCarouselCaptionRu] = useState("");
    const [carouselAltCaption, setCarouselAltCaption] = useState();
    const [carouselAltCaptionEn, setCarouselAltCaptionEn] = useState();
    const [carouselAltCaptionRu, setCarouselAltCaptionRu] = useState();
    const [carouselImg, setCarouselImg] = useState("");
    const [footerRichText, setFooterRichText] = useState("");
    const [footerRichTextEn, setFooterRichTextEn] = useState("");
    const [footerRichTextRu, setFooterRichTextRu] = useState("");
    const [footerContactUs, setFooterContactUs] = useState("");
    const [footerContactUsArr, setFooterContactUsArr] = useState([]);
    const [favoriteProjects, setFavoriteProjects] = useState([]);
    const [serviceCaption, setServiceCaption] = useState("");
    const [serviceCaptionEn, setServiceCaptionEn] = useState("");
    const [serviceCaptionRu, setServiceCaptionRu] = useState("");
    const [serviceRichText, setServiceRichText] = useState("");
    const [serviceRichTextEn, setServiceRichTextEn] = useState("");
    const [serviceRichTextRu, setServiceRichTextRu] = useState("");
    const [servicethumbnail, setServicethumbnail] = useState("");
    const [detailServices, setDetailServices] = useState([]);

    const [createFavoriteProject] = useMutation(CREATE_FAVORITE_PROJECT, { onCompleted: (data) => { setFavoriteProjects(oldArray => [...oldArray, data.createFavoriteProjects]); notifyFavoriteProject(); rf5(); } });
    const [deleteFavoriteProject] = useMutation(DELETE_FAVORITE_PROJECT, { onCompleted: (data) => { setFavoriteProjects(oldArray => [...oldArray]); notifyFavoriteProject(); rf5(); } });
    const [createMedia] = useMutation(CREATE_MEDIA, { onCompleted: (data) => { setMediaImageUrls(oldArray => [...oldArray, data.createFavoriteProjects]); setMediaImageUrl(null); setMediaTitle(""); notifyMedia(); rf7(); } });
    const [deleteMedia] = useMutation(DELETE_MEDIA, { onCompleted: (data) => { setMediaImageUrls(oldArray => [...oldArray]); notifyMedia(); rf7(); } });

    useEffect(() => {
        setAboutUs(data6 && data6.getAboutus && data6.getAboutus.aboutusRichText && data6.getAboutus.aboutusRichText)
        setAboutUsEn(data6 && data6.getAboutus && data6.getAboutus.aboutusRichTextEn && data6.getAboutus.aboutusRichTextEn)
        setAboutUsRu(data6 && data6.getAboutus && data6.getAboutus.aboutusRichTextRu && data6.getAboutus.aboutusRichTextRu)
        setOurVision(data6 && data6.getAboutus && data6.getAboutus.ourVisionRichText && data6.getAboutus.ourVisionRichText)
        setOurVisionEn(data6 && data6.getAboutus && data6.getAboutus.ourVisionRichTextEn && data6.getAboutus.ourVisionRichTextEn)
        setOurVisionRu(data6 && data6.getAboutus && data6.getAboutus.ourVisionRichTextRu && data6.getAboutus.ourVisionRichTextRu)
    }, [data6])

    useEffect(() => {
        setMediaImageUrls(mediaData && mediaData.getMedia && mediaData.getMedia)
    }, [mediaData])

    useEffect(() => {
        setFooterRichText(data4 && data4.getFooter && data4.getFooter.footerRichText && data4.getFooter.footerRichText)
        setFooterRichTextEn(data4 && data4.getFooter && data4.getFooter.footerRichTextEn && data4.getFooter.footerRichTextEn)
        setFooterRichTextRu(data4 && data4.getFooter && data4.getFooter.footerRichTextRu && data4.getFooter.footerRichTextRu)
        setFooterContactUsArr(data4 && data4.getFooter && (data4.getFooter.footerContactUs).length > 0 && data4.getFooter.footerContactUs)
    }, [data4])

    useEffect(() => {
        setFavoriteProjects(data5 && data5.getFavoriteProjects && data5.getFavoriteProjects)
    }, [data5])

    useEffect(() => {
        setDetailServices(data3 && data3.getDetailServices && data3.getDetailServices)
    }, [data3])

    const toggleDrawer = () => {
        setOpen(!open);
    };
    const handleChange = (event) => {
        if (event.target.value == "true") {
            setValues({ ...values, [event.target.name]: true });
        } else if (event.target.value == "false") {
            setValues({ ...values, [event.target.name]: false });
        } else {
            setValues({ ...values, [event.target.name]: event.target.value });
        }
    }

    const handleDeleteSingleImage = () => {
        setImageUrl(null);
        setValues({ ...values, thumbnail: null });
    }

    const handleDeleteMediaPreviewImage = () => {
        setMediaImageUrl(null);
    }

    const handleDeleteServiceImage = () => {
        setServicethumbnail(null);
    }

    const handleDeleteMultipleImage = (img) => {
        const filterIndex = values.images.indexOf(img);
        const filterIndex2 = imageUrls.indexOf(img);
        if (filterIndex > -1) {
            values.images.splice(filterIndex, 1);
            imageUrls.splice(filterIndex2, 1);
            setValues({ ...values, images: values.images.filter((img, i) => i !== img) });
            setImageUrls(imageUrls.filter((img, i) => i !== img));
        }
    }

    const fileUploadCarousel = (event) => {
        if ((event.target.files).length > 0) {

            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                setCarouselImg(reader.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleFileUpload = (event) => {

        if ((event.target.files).length > 0) {

            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                setImageUrl(reader.result);
                setValues({ ...values, thumbnail: reader.result });
            };

            reader.readAsDataURL(file);
        }
    }

    const handleFileUploadService = (event) => {
        if ((event.target.files).length > 0) {

            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                setServicethumbnail(reader.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleFileUploadforMedia = (event) => {
        if ((event.target.files).length > 0) {

            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                setMediaImageUrl(reader.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleFileUploads = (event) => {
        let newArr = []
        for (let i = 0; i < (event.target.files).length; i++) {
            const file = event.target.files[i];
            const reader = new FileReader();

            reader.onloadend = () => {
                newArr.push(reader.result)
                setImageUrls(oldArray => [...oldArray, reader.result]);
            };

            reader.readAsDataURL(file);
        }
        setValues({ ...values, images: newArr });
    };

    if (loading || loading2 || loading3 || loading4 || loading5 || loading6 || loadingMedia) return <Loading />
    if (error) console.log("error: " + error);
    else if (error2) console.log("error2: " + error2)
    else if (error3) console.log("error3: " + error3)
    else if (error4) console.log("error4: " + error4)
    else if (error5) console.log("error5: " + error5)
    else if (error6) console.log("error6: " + error6)
    else if (mediaError) console.log("mediaError: " + mediaError)

    const handleDeleteRow = (id) => {
        notify()
        deleteProject({ variables: { id } });
        refetch();
        /* setRows(rows.filter((row) => row.id !== id)); */
    };

    const handleDeleteCarousel = (id) => {
        notifyCarouselDelete()
        deleteCarousel({ variables: { id } });
        rf2();
        /* setRows(rows.filter((row) => row.id !== id)); */
    };

    const handleDeleteSingleCarouselImage = () => {
        setCarouselImg("")
    }

    const notify = () => {
        toast.info("Proje Siliniyor !", {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const notifyCarouselDelete = () => {
        toast.error("Atlıkarıncadan veri silindi !", {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const notifyAboutus = () => {
        toast.info("Hakkında bölümü güncellendi", {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const notifyCarouselSuccess = () => {
        toast.success("Atlıkarıncaya yeni veri eklendi", {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const notifyCarouselError = () => {
        toast.error("Atlıkarıncada eksiklikler var", {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const notifyMediaError = () => {
        toast.error("Bir sorun oluştu lütfen tekrar deneyiniz", {
            position: toast.POSITION.TOP_RIGHT
        });
    };


    const notifyDetailServices = () => {
        toast.success("Detaylı servis bilgisi eklendi", {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const notifyDetailServicesWarn = () => {
        toast.warning("Detaylı servis bilgisi silindi.", {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const notifyFavoriteProject = () => {
        toast.info("Favori projeler güncellendi", {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const notifyMedia = () => {
        toast.info("Medya güncellendi", {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const addProjectNotify = () => {
        toast.success("Yeni bir proje eklendi", {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const notifyFooter = () => {
        toast.info("Altbilgi güncellendi", {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    const notifyFooterSucc = () => {
        toast.success("Altbilgiye iletişim bilgisi eklendi", {
            position: toast.POSITION.TOP_RIGHT
        });
    }



    const notifyFooterWarn = () => {
        toast.warning("Altbilgiden iletişim bilgisi silindi", {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    const notifyFooterErr = () => {
        toast.error("Hata! lütfen girdiğiniz epostayı veya tel numarasını kontrol ediniz telefon numaralarının başına '0' getirilmelidir.", {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const handleRegisterContact = () => {
        if (footerContactUs.includes("@") || ("0" + parseInt(footerContactUs, 10).toString() === footerContactUs && footerContactUs.startsWith("0") && footerContactUs.length === 11)) {
            setFooterContactUsArr(oldArray => [...oldArray, footerContactUs]);
            updateFooter({ variables: { footerInput: { footerContactUs: [...footerContactUsArr, footerContactUs] } } });
            notifyFooterSucc()
        } else {
            notifyFooterErr()
        }
    }

    const notifyDetailServicesErr = () => {
        toast.error("Detaylı servis bilgilerinde eksiklikler var.", {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const addServiceDetail = () => {
        if (serviceCaption && serviceCaptionEn && serviceCaptionRu && serviceRichText && serviceRichTextEn && serviceRichTextRu && servicethumbnail) {
            setDetailServices(oldArray => [...oldArray, { serviceCaption: serviceCaption, serviceCaptionEn: serviceCaptionEn, serviceCaptionRu: serviceCaptionRu, serviceRichText: serviceRichText, serviceRichTextEn: serviceRichTextEn, serviceRichTextRu: serviceRichTextRu, servicethumbnail: servicethumbnail }]);
            createDetailService({ variables: { bodyDetailServices: { serviceCaption: serviceCaption, serviceCaptionEn: serviceCaptionEn, serviceCaptionRu: serviceCaptionRu, serviceRichText: serviceRichText, serviceRichTextEn: serviceRichTextEn, serviceRichTextRu: serviceRichTextRu, servicethumbnail: servicethumbnail } } });
            notifyDetailServices();
        } else {
            notifyDetailServicesErr();
        }
    }

    const handleAddCarousel = () => {
        if (carouselCaption && carouselCaptionEn && carouselCaptionRu && carouselAltCaption && carouselAltCaptionEn && carouselAltCaptionRu && carouselImg) {
            createCarousel({ variables: { bodyCarousel: { carouselCaption: carouselCaption, carouselCaptionEn: carouselCaptionEn, carouselCaptionRu: carouselCaptionRu, carouselAltCaption: carouselAltCaption, carouselAltCaptionEn: carouselAltCaptionEn, carouselAltCaptionRu: carouselAltCaptionRu, carouselImg: carouselImg } } });
            rf2();
            notifyCarouselSuccess();
        } else {
            notifyCarouselError();
        }
    }

    const handleMediaCreate = () => {
        if (mediaImageUrl) {
            createMedia({ variables: { bodyMedia: { media: mediaImageUrl, mediaTitle: mediaTitle } } })
            rf2();
        } else {
            notifyMediaError();
        }
    }


    return (
        <ThemeProvider theme={defaultTheme}>
            <ToastContainer />
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            {place === "main" && "Dashboard"}
                            {place === "addProject" && "Proje Ekle"}
                            {place === "settings" && "Ayarlar"}
                            {place === "media" && "Medya"}
                        </Typography>
                        <IconButton color="inherit" onClick={() => { logout(); navigate("/") }}>
                            <Logout />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        <React.Fragment>
                            <ListItemButton onClick={() => setPlace("main")}>
                                <ListItemIcon>
                                    <DashboardSharp />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItemButton>
                            <ListItemButton onClick={() => setPlace("addProject")}>
                                <ListItemIcon>
                                    <PersonAdd />
                                </ListItemIcon>
                                <ListItemText primary="Proje Ekle" />
                            </ListItemButton>
                            <ListItemButton disabled={true} onClick={() => setPlace("addReference")}>
                                <ListItemIcon>
                                    <PersonAddAlt1 />
                                </ListItemIcon>
                                <ListItemText primary="Referans Ekle" />
                            </ListItemButton>
                            <ListItemButton onClick={() => setPlace("settings")}>
                                <ListItemIcon>
                                    <Settings />
                                </ListItemIcon>
                                <ListItemText primary="Ayarlar" />
                            </ListItemButton>
                            <ListItemButton onClick={() => setPlace("media")}>
                                <ListItemIcon>
                                    <PermMedia />
                                </ListItemIcon>
                                <ListItemText primary="Medya" />
                            </ListItemButton>
                        </React.Fragment>
                    </List>
                </Drawer>
                {place === "main" && (
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                        <Container style={{ maxWidth: "95%" }} sx={{ mt: 4, mb: 4 }}>
                            <h3>Projelerim</h3>
                            <Grid >
                                <Grid item xs={12} md={12} lg={12}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: "100%",
                                        }}
                                    >
                                        <div className="App">
                                            <Table var1="Adı" var2="İş veren" var3="Statü" rows={data.getProjects} deleteRow={handleDeleteRow} />
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                            {/*                            <br />
                            <br />
                            <h3>Referanslarım</h3>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: "100%",
                                        }}
                                    >
                                        <div className="App">
                                            <Table var1="Adı" var2="İş veren" var3="Kategori" rows={data2.getReferences} deleteRow={handleDeleteRow} />
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid> */}
                            <Copyright sx={{ pt: 4 }} />
                        </Container>
                    </Box>
                )}
                {place === "addProject" && (
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: "100%",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                '& > :not(style)': { m: 2, width: '50ch' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField name="name" onChange={handleChange} label="İsim" variant="outlined" required />
                                            <TextField name="nameEn" onChange={handleChange} label="İsim(İngilizce)" variant="outlined" required />
                                            <TextField name="nameRu" onChange={handleChange} label="İsim(Rusça)" variant="outlined" required />
                                            <TextField
                                                label="Detay"
                                                multiline
                                                rows={4}
                                                required
                                                name="detail" onChange={handleChange}
                                            />
                                            <TextField
                                                label="Detay(İngilizce)"
                                                multiline
                                                rows={4}
                                                required
                                                name="detailEn" onChange={handleChange}
                                            />
                                            <TextField
                                                label="Detay(Rusça)"
                                                multiline
                                                rows={4}
                                                required
                                                name="detailRu" onChange={handleChange}
                                            />
                                            <TextField name="location" onChange={handleChange} label="Yer" variant="outlined" required />
                                            <TextField name="locationEn" onChange={handleChange} label="Yer(İngilizce)" variant="outlined" required />
                                            <TextField name="locationRu" onChange={handleChange} label="Yer(Rusça)" variant="outlined" required />
                                            <TextField name="relevantEntity" onChange={handleChange} label="İlgili Kurum" variant="outlined" required />
                                            <TextField name="relevantEntityEn" onChange={handleChange} label="İlgili Kurum(İngilizce)" variant="outlined" required />
                                            <TextField name="relevantEntityRu" onChange={handleChange} label="İlgili Kurum(Rusça)" variant="outlined" required />
                                            <TextField name="projectScope" onChange={handleChange} label="Proje Kapsamı" variant="outlined" required />
                                            <TextField name="projectScopeEn" onChange={handleChange} label="Proje Kapsamı(İngilizce)" variant="outlined" required />
                                            <TextField name="projectScopeRu" onChange={handleChange} label="Proje Kapsamı(Rusça)" variant="outlined" required />
                                            <TextField name="dateOfProject" onChange={handleChange} label="Tarih" variant="outlined" required />
                                            <TextField name="clientCompany" onChange={handleChange} label="İş Veren Firma" variant="outlined" required />
                                            <TextField name="clientCompanyEn" onChange={handleChange} label="İş Veren Firma(İngilizce)" variant="outlined" required />
                                            <TextField name="clientCompanyRu" onChange={handleChange} label="İş Veren Firma(Rusça)" variant="outlined" required />
                                            <br />
                                            <FormControlLabel control={<Checkbox />} onChange={handleChange} name="status" value={(values && values.status) ? (values.status == true) ? false : true : true} label="Tamamlandi mi?" />
                                            <br />
                                            <label htmlFor="upload-image">
                                                <Button variant="contained" component="span">
                                                    Vitrin Resimi Yükle
                                                </Button>
                                                <input
                                                    id="upload-image"
                                                    hidden
                                                    accept="image/*"
                                                    type="file"
                                                    onChange={handleFileUpload}
                                                    required
                                                />
                                            </label>
                                            {imageUrl && (
                                                <div style={{ position: "relative", width: "100%", maxWidth: "400px" }}>
                                                    <img src={imageUrl} alt="Ekinoks Mekanik ısıtma, soğutma, havalandırma, yangın sistemleri başlık fotoğrafı" height="300" />
                                                    <button className="btn-dashboard-del" onClick={() => handleDeleteSingleImage()}>X</button>
                                                </div>)}
                                            <br />
                                            <label htmlFor="upload-images">
                                                <Button variant="contained" component="span">
                                                    Kalan Resimleri Yükle
                                                </Button>
                                                <input
                                                    id="upload-images"
                                                    hidden
                                                    accept="image/*"
                                                    type="file" multiple name="files[]"
                                                    onChange={handleFileUploads}
                                                    required
                                                />
                                            </label>
                                            {imageUrls.length > 0 && imageUrls.map((imgurl, i) => (
                                                <div style={{ position: "relative", width: "100%", maxWidth: "400px" }} key={i}>
                                                    <img src={imgurl} alt="Ekinoks Mekanik Proje Detay Foroğrafları" height="300" />
                                                    <button className="btn-dashboard-del" onClick={() => handleDeleteMultipleImage(imgurl)}>X</button>
                                                </div>
                                            ))}
                                            <br />
                                            <Button disabled={createLoading} variant="contained" endIcon={<Send />} onClick={() => { createProject({ variables: { body: values, images: imageUrl, thumbnail: imageUrls } }); refetch(); addProjectNotify(); }}>Proje Ekle</Button>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Copyright sx={{ pt: 4 }} />
                        </Container>
                    </Box>
                )}
                {place === "addReference" && (
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                        <h3 style={{ marginTop: "60px" }}>Referans ekle</h3>
                        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: "100%",
                                        }}

                                    >
                                        <Box
                                            sx={{
                                                '& > :not(style)': { m: 2, width: '50ch' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField name="name" onChange={handleChange} label="İsim" variant="outlined" required />
                                            <TextField name="nameEn" onChange={handleChange} label="İsim(İngilizce)" variant="outlined" required />
                                            <TextField name="nameRu" onChange={handleChange} label="İsim(Rusça)" variant="outlined" required />
                                            <TextField
                                                label="Detay"
                                                multiline
                                                rows={4}
                                                required
                                                name="detail" onChange={handleChange}
                                            />
                                            <TextField
                                                label="Detay(İngilizce)"
                                                multiline
                                                rows={4}
                                                required
                                                name="detailEn" onChange={handleChange}
                                            />
                                            <TextField
                                                label="Detay(Rusça)"
                                                multiline
                                                rows={4}
                                                required
                                                name="detailRu" onChange={handleChange}
                                            />
                                            <TextField name="location" onChange={handleChange} label="Yer" variant="outlined" required />
                                            <TextField name="locationEn" onChange={handleChange} label="Yer(İngilizce)" variant="outlined" required />
                                            <TextField name="locationRu" onChange={handleChange} label="Yer(Rusça)" variant="outlined" required />
                                            <TextField name="relevantEntity" onChange={handleChange} label="İlgili Kurum" variant="outlined" required />
                                            <TextField name="relevantEntityEn" onChange={handleChange} label="İlgili Kurum(İngilizce)" variant="outlined" required />
                                            <TextField name="relevantEntityRu" onChange={handleChange} label="İlgili Kurum(Rusça)" variant="outlined" required />
                                            <TextField name="projectScope" onChange={handleChange} label="Proje Kapsamı" variant="outlined" required />
                                            <TextField name="projectScopeEn" onChange={handleChange} label="Proje Kapsamı(İngilizce)" variant="outlined" required />
                                            <TextField name="projectScopeRu" onChange={handleChange} label="Proje Kapsamı(Rusça)" variant="outlined" required />
                                            <TextField name="dateOfProject" onChange={handleChange} label="Tarih" variant="outlined" required />
                                            <TextField name="clientCompany" onChange={handleChange} label="İş Veren Firma" variant="outlined" required />
                                            <TextField name="clientCompanyEn" onChange={handleChange} label="İş Veren Firma(İngilizce)" variant="outlined" required />
                                            <TextField name="clientCompanyRu" onChange={handleChange} label="İş Veren Firma(Rusça)" variant="outlined" required />
                                            <br />
                                            <FormControlLabel control={<Checkbox />} onChange={handleChange} name="status" value={(values && values.status) ? (values.status == true) ? false : true : true} label="Tamamlandi mi?" />
                                            <br />
                                            <label htmlFor="upload-image">
                                                <Button variant="contained" component="span">
                                                    Vitrin Resimi Yükle
                                                </Button>
                                                <input
                                                    id="upload-image"
                                                    hidden
                                                    accept="image/*"
                                                    type="file"
                                                    onChange={handleFileUpload}
                                                    required
                                                />
                                            </label>
                                            {imageUrl && (
                                                <div style={{ position: "relative", width: "100%", maxWidth: "400px" }}>
                                                    <img src={imageUrl} alt="fotograf" height="300" />
                                                    <button className="btn-dashboard-del" onClick={() => handleDeleteSingleImage()}>X</button>
                                                </div>)}
                                            <br />
                                            <label htmlFor="upload-images">
                                                <Button variant="contained" component="span">
                                                    Kalan Resimleri Yükle
                                                </Button>
                                                <input
                                                    id="upload-images"
                                                    hidden
                                                    accept="image/*"
                                                    type="file" multiple name="files[]"
                                                    onChange={handleFileUploads}
                                                    required
                                                />
                                            </label>
                                            {imageUrls.length > 0 && imageUrls.map((imgurl, i) => (
                                                <div style={{ position: "relative", width: "100%", maxWidth: "400px" }} key={i}>
                                                    <img src={imgurl} alt="fotograf" height="300" />
                                                    <button className="btn-dashboard-del" onClick={() => handleDeleteMultipleImage(imgurl)}>X</button>
                                                </div>
                                            ))}
                                            <br />
                                            <Button disabled={createLoading} variant="contained" endIcon={<Send />} onClick={() => { createProject({ variables: { body: values, images: imageUrl, thumbnail: imageUrls } }); refetch(); }}>Proje Ekle</Button>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Copyright sx={{ pt: 4 }} />
                        </Container>
                    </Box>
                )}
                {place === "settings" && (
                    <Container style={{ maxWidth: "95%" }} sx={{ mt: 4, mb: 4 }}>
                        <h3>Referanslarım</h3>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: "100%",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            '& > :not(style)': { m: 2, width: '70ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <Divider style={{ width: "100%" }}>
                                            <Chip label="Ana Sayfa Görselleri" />
                                        </Divider>
                                        <TextField onChange={(e) => setCarouselCaption(e.target.value)} value={carouselCaption} label="Ana Sayfa Görsel Başlığı" variant="outlined" required />
                                        <TextField onChange={(e) => setCarouselCaptionEn(e.target.value)} value={carouselCaptionEn} label="Ana Sayfa Görsel Başlığı(En)" variant="outlined" required />
                                        <TextField onChange={(e) => setCarouselCaptionRu(e.target.value)} value={carouselCaptionRu} label="Ana Sayfa Görsel Başlığı(RU)" variant="outlined" required />
                                        <TextField onChange={(e) => setCarouselAltCaption(e.target.value)} value={carouselAltCaption} label="Ana Sayfa Görsel Alt Başlığı" variant="outlined" required />
                                        <TextField onChange={(e) => setCarouselAltCaptionEn(e.target.value)} value={carouselAltCaptionEn} label="Ana Sayfa Görsel Alt Başlığı(En)" variant="outlined" required />
                                        <TextField onChange={(e) => setCarouselAltCaptionRu(e.target.value)} value={carouselAltCaptionRu} label="Ana Sayfa Görsel Alt Başlığı(Ru)" variant="outlined" required />
                                        <label htmlFor="upload-image-carousel">
                                            <Button variant="contained" component="span">
                                                Resimi Yükle
                                            </Button>
                                            <input
                                                id="upload-image-carousel"
                                                hidden
                                                accept="image/*"
                                                type="file"
                                                onChange={fileUploadCarousel}
                                                required
                                            />
                                        </label>
                                        {carouselImg && (
                                            <div style={{ position: "relative", width: "100%", maxWidth: "400px" }}>
                                                <img src={carouselImg} alt="Ekinoks Mekanik" height="300" />
                                                <button className="btn-dashboard-del" onClick={() => handleDeleteSingleCarouselImage(carouselImg)}>X</button>
                                            </div>
                                        )}
                                        <Button disabled={loadingCarousel} variant="contained" component="span" onClick={() => { handleAddCarousel() }}>
                                            Atlıkarıncaya Ekle
                                        </Button>
                                        {data2.getCarousels.length > 0 && data2.getCarousels.map((imgurl, i) => (
                                            <div style={{ position: "relative", width: "100%", maxWidth: "400px" }} key={i}>
                                                <img src={imgurl.carouselImg} alt="Ekinoks Mekanik" height="300" style={{ filter: "blur(2px)" }} />
                                                <h1 style={{ position: "absolute", top: "50%", left: "50%", transform: "Translate(-50%, -50%)" }}>{imgurl.carouselCaption}</h1>
                                                <button className="btn-dashboard-del" onClick={() => handleDeleteCarousel(imgurl.id)}>X</button>
                                            </div>
                                        ))}

                                        <Divider style={{ width: "100%" }}>
                                            <Chip label="Favori Projeler" />
                                        </Divider>
                                        <div className='container_project_list'>
                                            {data.getProjects.length > 0 && data.getProjects.map((imgurl, i) => (
                                                <div className="responsive_project_list" key={i}>
                                                    <div className="gallery_project_list" onClick={() => { (favoriteProjects && favoriteProjects.some(el => el.projectId === imgurl.id) ? deleteFavoriteProject({ variables: { id: imgurl.id } }) : createFavoriteProject({ variables: { bodyFavoriteProjects: { name: imgurl.name, nameEn: imgurl.nameEn, nameRu: imgurl.nameRu, thumbnail: imgurl.thumbnail, projectId: imgurl.id } } })); }} style={{ borderStyle: (favoriteProjects && favoriteProjects.some(el => el.projectId === imgurl.id) ? "groove" : "none"), borderWidth: (favoriteProjects && favoriteProjects.some(el => el.projectId === imgurl.id) && "10px"), borderColor: (favoriteProjects && favoriteProjects.some(el => el.projectId === imgurl.id) && "#FFD369") }}>
                                                        <img src={imgurl.thumbnail} alt="Ekinoks Mekanik" width="600" height="400" />
                                                        <div className="desc_project_list">{imgurl.name}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <Divider style={{ width: "100%", background: "#FFD369" }}>
                                            <Chip label="Hakkımızda" />
                                        </Divider>
                                        <ReactQuill style={{ width: "100%" }} theme="snow" value={aboutUs} onChange={setAboutUs} placeholder='Hakkımızda' />
                                        <Divider style={{ width: "100%", background: "#FFD369" }}>
                                            <Chip label="Hakkımızda(İngilizce)" />
                                        </Divider>
                                        <ReactQuill style={{ width: "100%" }} theme="snow" value={aboutUsEn} onChange={setAboutUsEn} placeholder='Hakkımızda(En)' />
                                        <Divider style={{ width: "100%", background: "#FFD369" }}>
                                            <Chip label="Hakkımızda(Rusça)" />
                                        </Divider>
                                        <ReactQuill style={{ width: "100%" }} theme="snow" value={aboutUsRu} onChange={setAboutUsRu} placeholder='Hakkımızda(Ru)' />
                                        <Divider style={{ width: "100%", background: "rgb(34, 40, 49)" }}>
                                            <Chip label="Vizyonumuz" color='warning' />
                                        </Divider>
                                        <ReactQuill style={{ width: "100%" }} theme="snow" defaultValue={data6 && data6.getAboutus && data6.getAboutus.ourVisionRichText && data6.getAboutus.ourVisionRichText} value={ourVision} onChange={setOurVision} placeholder='Vizyonumuz' />
                                        <Divider style={{ width: "100%", background: "rgb(34, 40, 49)" }}>
                                            <Chip label="Vizyonumuz(İngilizce)" color='warning' />
                                        </Divider>
                                        <ReactQuill style={{ width: "100%" }} theme="snow" defaultValue={data6 && data6.getAboutus && data6.getAboutus.ourVisionRichTextEn && data6.getAboutus.ourVisionRichTextEn} value={ourVisionEn} onChange={setOurVisionEn} placeholder='Vizyonumuz(En)' />
                                        <Divider style={{ width: "100%", background: "rgb(34, 40, 49)" }}>
                                            <Chip label="Vizyonumuz(Rusça)" color='warning' />
                                        </Divider>
                                        <ReactQuill style={{ width: "100%" }} theme="snow" defaultValue={data6 && data6.getAboutus && data6.getAboutus.ourVisionRichTextRu && data6.getAboutus.ourVisionRichTextRu} value={ourVisionRu} onChange={setOurVisionRu} placeholder='Vizyonumuz(Ru)' />
                                        <Button variant='contained' disabled={loadingAboutus} onClick={() => { updateAboutus({ variables: { aboutusInput: { aboutusRichText: aboutUs, aboutusRichTextEn: aboutUsEn, aboutusRichTextRu: aboutUsRu, ourVisionRichText: ourVision, ourVisionRichTextEn: ourVisionEn, ourVisionRichTextRu: ourVisionRu } } }); notifyAboutus() }} >Hakkımızda Kaydet</Button>
                                        <Divider style={{ width: "100%" }}>
                                            <Chip label="Servislerimiz" />
                                        </Divider>
                                        <TextField onChange={(e) => setServiceCaption(e.target.value)} value={serviceCaption} label="Servis başlığı" variant="outlined" required />
                                        <TextField onChange={(e) => setServiceCaptionEn(e.target.value)} value={serviceCaptionEn} label="Servis başlığı(En)" variant="outlined" required />
                                        <TextField onChange={(e) => setServiceCaptionRu(e.target.value)} value={serviceCaptionRu} label="Servis başlığı(Ru)" variant="outlined" required />
                                        <label htmlFor="upload-image-service">
                                            <Button variant="contained" component="span">
                                                Vitrin Resimi Yükle
                                            </Button>
                                            <input
                                                id="upload-image-service"
                                                hidden
                                                accept="image/*"
                                                type="file"
                                                onChange={handleFileUploadService}
                                                required
                                            />
                                        </label>
                                        {servicethumbnail && (
                                            <div style={{ position: "relative", width: "100%", maxWidth: "400px" }}>
                                                <img src={servicethumbnail} alt="Ekinoks Mekanik" height="300" />
                                                <button className="btn-dashboard-del" onClick={() => handleDeleteServiceImage()}>X</button>
                                            </div>)}
                                        <Divider style={{ width: "100%", background: "rgb(34, 40, 49)" }}>
                                            <Chip label="Servis Detayı" color='warning' />
                                        </Divider>
                                        <ReactQuill style={{ width: "100%" }} theme="snow" value={serviceRichText} onChange={setServiceRichText} />
                                        <Divider style={{ width: "100%", background: "rgb(34, 40, 49)" }}>
                                            <Chip label="Servis Detayı(İngilizce)" color='warning' />
                                        </Divider>
                                        <ReactQuill style={{ width: "100%" }} theme="snow" value={serviceRichTextEn} onChange={setServiceRichTextEn} />
                                        <Divider style={{ width: "100%", background: "rgb(34, 40, 49)" }}>
                                            <Chip label="Servis Detayı(Rusça)" color='warning' />
                                        </Divider>
                                        <ReactQuill style={{ width: "100%" }} theme="snow" value={serviceRichTextRu} onChange={setServiceRichTextRu} />
                                        <Button variant='contained' disabled={loadingServices} onClick={() => addServiceDetail()} >Servis Detayını Kaydet</Button>
                                        {detailServices.length > 0 && detailServices.map((data, i) => (
                                            <ul key={i} style={{ width: "300px" }}>
                                                <li className='dashboard-footer-paragraph' onClick={() => { if (window.confirm('Silmek istediğinize emin misiniz?')) { setDetailServices(detailServices.filter((i) => i !== data)); deleteDetailService({ variables: { id: data.id } }); notifyDetailServicesWarn(); } }}>{data.serviceCaption} <span style={{ color: "#ed6c02" }}>|| Silmek için basınız</span></li>
                                            </ul>
                                        ))}
                                        {/* burada alınan servis başlığı link olacak ve linkte services/:{başlık} şeklinde değiştirilecek. */}
                                        <Divider style={{ width: "100%" }}>
                                            <Chip label="Altbilgi" />
                                        </Divider>
                                        <TextField onChange={(e) => setFooterRichText(e.target.value)} value={footerRichText} label="Altbilgi" variant="outlined" required />
                                        <TextField onChange={(e) => setFooterRichTextEn(e.target.value)} value={footerRichTextEn} label="Altbilgi(En)" variant="outlined" required />
                                        <TextField onChange={(e) => setFooterRichTextRu(e.target.value)} value={footerRichTextRu} label="Altbilgi(RU)" variant="outlined" required />
                                        <br />
                                        <Button variant='contained' disabled={loadingFooter} onClick={() => { updateFooter({ variables: { footerInput: { footerRichText: footerRichText, footerRichTextEn: footerRichTextEn, footerRichTextRu: footerRichTextRu } } }); notifyFooter() }} >Altbilgi Kaydet</Button>
                                        <br />
                                        <TextField onChange={(e) => setFooterContactUs(e.target.value)} value={footerContactUs} label="İletişim" variant="outlined" required />
                                        <Button variant='contained' disabled={loadingFooter} onClick={() => handleRegisterContact()} >İletişim Bilgisini Kaydet</Button>
                                        {footerContactUsArr.length > 0 && footerContactUsArr.map((data, i) => (
                                            <ul key={i} style={{ width: "300px" }}>
                                                <li className='dashboard-footer-paragraph' onClick={() => { if (window.confirm('Silmek istediğinize emin misiniz?')) { setFooterContactUsArr(footerContactUsArr.filter((i) => i !== data)); updateFooter({ variables: { footerInput: { footerContactUs: footerContactUsArr.filter((i) => i !== data) } } }); notifyFooterWarn(); } }}>{data} <span style={{ color: "#ed6c02" }}>|| Silmek için basınız</span></li>
                                            </ul>
                                        ))}
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                )}
                {place === "media" && (
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: "150%",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                '& > :not(style)': { m: 2, width: '50ch' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField onChange={(e) => setMediaTitle(e.target.value)} value={mediaTitle} label="Medya başlığı" variant="outlined" required />
                                            <label htmlFor="upload-image">
                                                <Button variant="contained" component="span">
                                                    Medya Resimi Yükle
                                                </Button>
                                                <input
                                                    id="upload-image"
                                                    hidden
                                                    accept="image/*"
                                                    type="file"
                                                    onChange={handleFileUploadforMedia}
                                                    required
                                                />
                                            </label>
                                            {mediaImageUrl && (
                                                <div style={{ position: "relative", width: "100%", maxWidth: "400px" }}>
                                                    <img src={mediaImageUrl} alt="Ekinoks Mekanik ısıtma, soğutma, havalandırma, yangın sistemleri başlık fotoğrafı" height="300" />
                                                    <button className="btn-dashboard-del" onClick={() => handleDeleteMediaPreviewImage()}>X</button>
                                                </div>)}
                                            <br />
                                            <Button disabled={createLoading} variant="contained" endIcon={<Send />} onClick={() => handleMediaCreate()}>Proje Ekle</Button>
                                            <div style={{display:"flex", flexWrap:"wrap", width:"100%", height:"100%"}}>
                                                {mediaImageUrls && mediaImageUrls.length > 0 && mediaImageUrls.map((imgurl, i) => (
                                                    <div style={{ position: "relative", width: "100%", maxWidth: "400px", marginRight:"9%" }} key={i}>
                                                        <div style={{ width: "100%", height: "100%" }}>
                                                            <img src={imgurl && imgurl.media} alt="Ekinoks Mekanik Proje Detay Foroğrafları" height="100%" width="100%" style={{ position: "relative", objectFit: "contain" }} />
                                                            <span style={{ position: "absolute" }}>{imgurl && imgurl.mediaTitle}</span>
                                                            <button className="btn-dashboard-del" onClick={() => { if (window.confirm('Silmek istediğinize emin misiniz?')) deleteMedia({ variables: { id: imgurl && imgurl.id } }) }} > X</button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                )}

            </Box>
        </ThemeProvider >
    );
}
