import React, { useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./contact.css"
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from "react-i18next";

export default function ContactUs() {
    const { t } = useTranslation();


    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_m5zu5y6', 'template_tsgg8lm', form.current, '723hu9NS8Qw-EyRZb')
            .then((result) => {
                toast.success('Mailiniz tarafamıza ulaşmıştır..');
            }, (error) => {
                toast.error("Bir sorun oluştu şuan mailinizi gönderemiyoruz.")
                console.log(error.text);
            });
    };

    return (
        <Container>
            <Toaster
                position="top-center"
                reverseOrder={true}
            />
            <Row className="mb-5 mt-3">
                <Col>
                    <div className="contactHeader">
                    {t("contact")}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg="5" className="mb-5">
                    <h3 className="py-4">{t("contactWithUs")}</h3>
                    <address className="text-left">
                        <strong>{t("email")}:</strong>{" "}
                        <a className="contactEmailLink" href={`mailto:${"info@ekinoksmekanik.com.tr"}`}>
                            info@ekinoksmekanik.com.tr
                        </a>
                        <br />
                        <br />

                        <p>
                            <strong>{t("tel")}:</strong> <a className="contactEmailLink" href="tel:+02162250037">(0216) 225 0037</a>
                        </p>

                        <p className="contactEmailLink">
                            <strong>{t("address")}:</strong>Yenişehir, Mustafa Akyol Sokağı No:7, 34912 Pendik/İstanbul
                        </p>

                    </address>
                    <hr />
                    <p>
                        {t("contactDetail")}
                        <br />
                        <br />
                        {t("contactDetail2")}
                    </p>
                </Col>
                <Col lg="7" className="d-flex align-items-center">
                    <form className="contact__form w-100" ref={form} onSubmit={sendEmail}>
                        <Row className="py-3">
                            <Col lg="4" className="form-group">
                                <input
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    placeholder={t("yourName")}
                                    autoComplete="nope"
                                    type="text"
                                    required
                                />
                            </Col>
                            <Col lg="4" className="form-group">
                                <input
                                    className="form-control rounded-0"
                                    id="email"
                                    name="email"
                                    placeholder={t("email")}
                                    autoComplete="nope"
                                    type="email"
                                    required
                                />
                            </Col>
                            <Col lg="4" className="form-group">
                                <input
                                    className="form-control"
                                    id="tel"
                                    name="tel"
                                    placeholder={t("tel")}
                                    type="tel"
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" className="form-group">
                                <input
                                    className="form-control rounded-0"
                                    id="subject"
                                    name="subject"
                                    placeholder={t("subject")}
                                    type="text"
                                    required
                                />
                            </Col>
                        </Row>
                        <textarea
                            className="form-control rounded-0 my-3"
                            id="message"
                            name="message"
                            placeholder={t("message")}
                            rows="5"
                            required
                        ></textarea>
                        <Row>
                            <div className="d-grid">
                                <Button variant="dark" size="lg" type="submit">
                                    {t("send")}
                                </Button>
                            </div>
                        </Row>
                    </form>
                </Col>
            </Row>
            <div className="google-maps">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10743.105009116309!2d29.315722140730664!3d40.9227202617658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cadb4703f13157%3A0x50ce7a374fc43dcb!2sMerlin%20Sitesi!5e0!3m2!1str!2str!4v1689362370143!5m2!1str!2str" title="googleMaps" width="1300" height="600" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </Container>
    );
}