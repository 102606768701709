/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import { Container } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import { Search, OpenInFull } from '@mui/icons-material';
import 'react-image-lightbox/style.css';
import './featuredProjects.css';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
/* import { useQuery } from "@apollo/client";
import { GET_FAVORITEPROJECTS } from "../../graphql/query";
import { Skeleton } from "@mui/material"; */
import i18next from "i18next";

const FeaturedProjectArray = [
    {
        id: "651e7b7e008ee9ada854f544",
        name: "HEKTAŞ TARIMSAL İNOVASYON EĞİTİM VE DENEYİM MERKEZİ",
        nameEn: "HEKTAŞ AGRICULTURAL INNOVATION TRAINING AND EXPERIENCE CENTER",
        nameRu: "ХЕКТАШ СЕЛЬСКОХОЗЯЙСТВЕННЫЙ ИННОВАЦИОННЫЙ ЦЕНТР ОБУЧЕНИЯ И ОПЫТА",
        projectId: "64bd5ae372e746463498a8ac",
        thumbnail: "/assets/fav1.png",
    },
    {
        id: "651e7b7f008ee9ada854f549",
        name: "ATATÜRK KÜLTÜR DİL VE TARİH YÜKSEK KURUMU",
        nameEn: "ATATÜRK CULTURE, LANGUAGE AND HISTORY HIGH INSTITUTION",
        nameRu: "ВЫСШИЙ УЧРЕЖДЕНИЕ КУЛЬТУРЫ, ЯЗЫКА И ИСТОРИИ АТАТЮРК",
        projectId: "651ab1cf7d9a1ab169a5f084",
        thumbnail: "/assets/fav2.png",
    },
    {
        id: "651e7b81008ee9ada854f552",
        name: "KIRGIZİSTAN CUMHURBAŞKANLIĞI BİNASI",
        nameEn: "KYRGYZSTAN PRESIDENCY BUILDING",
        nameRu: "ДОМ ПРЕЗИДЕНТСТВА КЫРГЫЗСТАНА",
        projectId: "651dbbf351b6ab393147a446",
        thumbnail: "/assets/kirgiz.png",
    }
]

const FeaturedProjects = () => {
    const { t } = useTranslation();
    const currentLanguage = i18next.language;

    const [isOpen, setIsopen] = useState(false);
    const [part, setPart] = useState("");
    // const { loading, error, data } = useQuery(GET_FAVORITEPROJECTS);
    /*  if (loading) return (
         (
             <Container className='featuredProjectsContainer'>
                 <h1 className='heading'>{t('favorite')} <span>{t('projects')}</span></h1>
                 <Swiper
                     slidesPerView={window.screen.width > 760 ? (window.screen.width < 1200 ? 2 : 3) : 1}
                     spaceBetween={30}
                     loop={true}
                     pagination={{
                         clickable: true,
                     }}
                     navigation={true}
                     modules={[Pagination, Navigation]}
                     className="mySwiper"
                     style={{ "--swiper-navigation-color": "#FFD369", "--swiper-pagination-color": "#FFD369" }}
                 >
                     <React.Fragment>
                         <SwiperSlide style={{ opacity: "1" }} key={"1"}>
                             <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                         </SwiperSlide>
                         <SwiperSlide style={{ opacity: "1" }} key={"2"}>
                             <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                         </SwiperSlide>
                         <SwiperSlide style={{ opacity: "1" }} key={"3"}>
                             <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                         </SwiperSlide>
                     </React.Fragment>
                 </Swiper>
             </Container >
         )
     )
     if (error) console.log(error) */
    return (
        <Container className='featuredProjectsContainer'>
            <h1 className='heading'>{t('favorite')} <span>{t('projects')}</span></h1>
            <Swiper
                slidesPerView={window.screen.width > 760 ? (window.screen.width < 1200 ? 2 : 3) : 1}
                spaceBetween={30}
                loop={false}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                style={{ "--swiper-navigation-color": "#FFD369", "--swiper-pagination-color": "#FFD369" }}
            >
                {FeaturedProjectArray.map((data, i) => (
                    <SwiperSlide style={{ opacity: "1" }} key={i}>
                        <div className="col item">
                            <div className="box" style={{ backgroundImage: `url(${data.thumbnail})` }}>
                                <div className="cover">
                                    <h3 className="name">{currentLanguage !== "tr" ? currentLanguage === "en" ? data.nameEn : data.nameRu : data.name}</h3>
                                    <div className="social"><Link to={`/projects/${data.projectId}`}><Search /></Link><a href="#" onClick={() => { setIsopen(true); setPart(data.id) }}>  <OpenInFull />
                                        {(isOpen && data.id === part) && (
                                            <Lightbox
                                                mainSrc={data.thumbnail}
                                                onImageLoad={() => {
                                                    window.dispatchEvent(new Event('resize'));
                                                }}
                                                onCloseRequest={() => setIsopen(false)}
                                            />
                                        )}
                                    </a></div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Container >
    )
}

export default FeaturedProjects