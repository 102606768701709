import { Image } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

import "./carousels.css"
/* import { GET_CAROUSELS } from '../../graphql/query';
import { useQuery } from '@apollo/client';
import { Skeleton } from '@mui/material'; */
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';


const carouselArray = [
    {
        carouselAltCaption: "ÇANKAYA / ANKARA",
        carouselAltCaptionEn: "ÇANKAYA / ANKARA",
        carouselAltCaptionRu: "ÇANKAYA / ANKARA",
        carouselCaption: "ATATÜRK KÜLTÜR DİL VE TARİH YÜKSEK KURUMU",
        carouselCaptionEn: "ATATÜRK KÜLTÜR DİL VE TARİH YÜKSEK KURUMU",
        carouselCaptionRu: "ATATÜRK KÜLTÜR DİL VE TARİH YÜKSEK KURUMU",
        carouselImg: "/assets/yildiz1.png",
        id: "651d6ababbdd9baef6e9a070"
    },
    {
        carouselAltCaption: "ORHANGAZİ  / BURSA",
        carouselAltCaptionEn: "ORHANGAZİ  / BURSA",
        carouselAltCaptionRu: "ORHANGAZİ  / BURSA",
        carouselCaption: "HEKTAŞ TARIMSAL İNOVASYON EĞİTİM VE DENEYİM MERKEZİ",
        carouselCaptionEn: "HEKTAŞ TARIMSAL İNOVASYON EĞİTİM VE DENEYİM MERKEZİ",
        carouselCaptionRu: "HEKTAŞ TARIMSAL İNOVASYON EĞİTİM VE DENEYİM MERKEZİ",
        carouselImg: "/assets/hektasGenel.png",
        id: "651d6c3bbbdd9baef6e9a0a3"
    }
    , {
        carouselAltCaption: "BİŞKEK / KIRGIZİSTAN",
        carouselAltCaptionEn: "BISHKEK / KYRGYZSTAN",
        carouselAltCaptionRu: "БИШКЕК / КЫРГЫЗСТАН",
        carouselCaption: "KIRGIZİSTAN CUMHURBAŞKANLIĞI BİNASI",
        carouselCaptionEn: "KYRGYZSTAN PRESIDENCY BUILDING",
        carouselCaptionRu: "ДОМ ПРЕЗИДЕНТСТВА КЫРГЫЗСТАНА",
        carouselImg: "/assets/kirgiz.png",
        id: "651e77da008ee9ada854f4fa"
    }
]

function Carousels() {
    const { t } = useTranslation();
    const currentLanguage = i18next.language;
/*     const { loading, error, data } = useQuery(GET_CAROUSELS);
    if (loading) return < Skeleton animation="wave" variant="rectangular" width={'100vw'} height={'60vh'} />
    if (error) console.log(error)
    const carouselsData = data.getCarousels */

    return (
        <Carousel>
            {carouselArray.map((data, i) => (
                <Carousel.Item className='carausel-custom' interval={5000} key={i} >
                    <Image
                        className="d-block w-100 h-100 border rounded"
                        src={data.carouselImg}
                        alt={t('home')}
                        style={{ objectFit: (data.carouselCaption === "." && data.carouselAltCaption === ".") ? "fill !important" : "cover" }}
                    />
                    {!(data.carouselCaption === "." && data.carouselAltCaption === ".") && (
                        <Carousel.Caption style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                            <h3 className='carauselHeader'>{currentLanguage !== "tr" ? currentLanguage === "en" ? data.carouselCaptionEn : data.carouselCaptionRu : data.carouselCaption}</h3>
                            <p className='carauselParagraph'>{currentLanguage !== "tr" ? currentLanguage === "en" ? data.carouselAltCaptionEn : data.carouselAltCaptionRu : data.carouselAltCaption}</p>
                        </Carousel.Caption>
                    )}
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default Carousels;