import React from 'react'
import "./servicesPage.css"
import SidebarWidget from '../../components/sidebar/SidebarWidget'
/* import { GET_DETAILSERVICES } from '../../graphql/query'
import { useQuery } from '@apollo/client'
import Loading from '../../components/loading/Loading' */
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const servicesArray = [
    {
        "id": "65269adbbc7e86d175c91dfb",
        "serviceCaption": "YANGIN SÖNDÜRME SİSTEMLERİ",
        "serviceCaptionEn": "FIRE FIGHTING SYSTEMS",
        "serviceCaptionRu": "СИСТЕМЫ ПОЖАРОТУШЕНИЯ",
        "servicethumbnail": "/assets/yangin.png",
        "serviceRichText": "<p>Yangın söndürme tesisatı, binalarda yangınların kontrol altına alınması amacıyla kullanılan bir sistemdir. Bu tesisat, yangın algılama ekipmanları, otomatik söndürme sistemleri, yangın hortumları ve yangın söndürücüler gibi bileşenleri içerir. Yangın algılandığında, otomatik olarak çalışan bu sistemler, yangını lokalize eder ve söndürme maddelerini kullanarak müdahale eder. Bu sayede yangın hasarı minimize edilir ve can kaybı riski azalır.</p>",
        "serviceRichTextEn": "<p>Fire suppression systems are used in buildings to control and extinguish fires. These systems include components such as fire detection equipment, automatic fire suppression systems, fire hoses, and fire extinguishers. When a fire is detected, these systems automatically activate, localize the fire, and intervene using extinguishing agents. This helps minimize fire damage and reduces the risk of loss of life.</p>",
        "serviceRichTextRu": "<p>Системы тушения пожаров используются в зданиях для контроля и тушения пожаров. Эти системы включают в себя компоненты, такие как оборудование для обнаружения пожара, автоматические системы тушения пожара, пожарные шланги и огнетушители. При обнаружении пожара эти системы автоматически активируются, локализуют пожар и вмешиваются с применением средств для тушения. Это помогает минимизировать ущерб от пожара и снижает риск потери человеческих жизней.</p>",
    },
    {
        "id": "652a619233cce5095133369b",
        "serviceCaption": "HAVALANDIRMA SİSTEMLERİ",
        "serviceCaptionEn": "VENTILATION SYSTEM",
        "serviceCaptionRu": "ВЕНТИЛЯЦИОННЫЕ СИСТЕМЫ",
        "serviceRichText": "<p>Havalandırma sistemleri, iç mekanlardaki hava kalitesini iyileştirmek ve taze hava sağlamak için kullanılan tesisat sistemleridir. Bu sistemler, kirli hava dışarıya atılırken temiz hava içeri çekilmesini sağlar. Havalandırma, sağlıklı bir iç mekan iklimi oluşturur, kötü kokuları giderir ve potansiyel sağlık sorunlarını azaltır. Genellikle filtreler kullanarak hava temizlenir ve sirkülasyon sağlanır. Havalandırma sistemleri, enerji verimliliğini artırmak ve konforu iyileştirmek için önemlidir.</p>",
        "serviceRichTextEn": "<p>Ventilation systems are plumbing systems used to improve indoor air quality and provide fresh air. These systems allow for the extraction of stale air while bringing in fresh air. Ventilation creates a healthy indoor environment, removes odors, and reduces potential health issues. Air is typically filtered and circulated using ventilation systems, contributing to energy efficiency and enhanced comfort.</p>",
        "serviceRichTextRu": "<p>Системы вентиляции - это системы водопровода, используемые для улучшения качества воздуха в помещении и обеспечения свежего воздуха. Эти системы позволяют извлекать отработанный воздух, обеспечивая приток свежего. Вентиляция создает здоровую внутреннюю среду, устраняет запахи и снижает возможные проблемы с здоровьем. Вентиляция обычно фильтрует и циркулирует воздух, что способствует повышению энергоэффективности и улучшению комфорта.</p>",
        "servicethumbnail": "/assets/havalandirma.png"
    },
    {
        "id": "652a62e533cce509513336ea",
        "serviceCaption": "ISITMA & SOĞUTMA SİSTEMLERİ",
        "serviceCaptionEn": "HEATING & COOLING SYSTEM",
        "serviceCaptionRu": "СИСТЕМЫ ОТОПЛЕНИЯ И ОХЛАЖДЕНИЯ",
        "serviceRichText": "<p><span style=\"color: rgb(33, 37, 41);\">Isıtma ve soğutma sistemleri, iç mekanların sıcaklığını kontrol etmek amacıyla kullanılan tesisat sistemleridir. Isıtma sistemleri genellikle kış aylarında iç mekanları sıcak tutmak için kullanılır ve sıcak su veya sıcak hava dağıtarak çalışır. Soğutma sistemleri ise yaz aylarında iç mekanları serinletmek için kullanılır ve genellikle buharlaştırma veya sıkıştırma işlemlerini kullanarak sıcaklığı düşürür. Bu sistemler, konforlu bir iç mekan iklimi sağlamanın yanı sıra enerji tasarrufu sağlamak ve iç mekan hava kalitesini iyileştirmek için önemlidir. Isıtma ve soğutma sistemleri, farklı türlerde ve özelliklerde gelir, örneğin, merkezi ısıtma, klima sistemleri, yerden ısıtma gibi çeşitli seçenekler bulunur.</span></p>",
        "serviceRichTextEn": "<p><span style=\"color: rgb(33, 37, 41);\">Heating and cooling systems are plumbing systems commonly used. Due to the significant drop in outdoor temperatures during winter and the rise in temperatures during summer, it is necessary to heat and cool indoor spaces. The process of raising or lowering the temperature of one or more volumes to the desired level and maintaining it at a specific temperature is achieved through heating and cooling systems. Thermal comfort is essential not only for people but also for all sensitive devices and machines.</span></p>",
        "serviceRichTextRu": "<p><span style=\"color: rgb(33, 37, 41);\">Системы отопления и охлаждения - это распространенные системы трубопроводов. Из-за существенного падения температуры на улице зимой и повышения температуры летом необходимо обогревать и охлаждать внутренние помещения. Процесс поднятия или понижения температуры одного или нескольких объемов до желаемого уровня и поддержание ее на определенной температуре достигается с помощью систем отопления и охлаждения. Тепловой комфорт необходим не только для людей, но и для всех чувствительных устройств и машин.</span></p>",
        "servicethumbnail": "/assets/isitmaSogutma.png"
    },
    {
        "id": "652aa53dcf6e8dea9b696b8c",
        "serviceCaption": "SIHHİ TESİSAT",
        "serviceCaptionEn": "PLUMBING",
        "serviceCaptionRu": "САНТЕХНИКА",
        "serviceRichText": "<p>Sıhhi tesisat, bir yapıda suyun depolanması, ısınması, soğutulması, dağıtılması ve atılmasını sağlayan sistemlerin tümüdür. Sıhhi tesisat, genellikle evler, iş yerleri ve endüstriyel tesisler gibi yapılarda kullanılır. Bu sistemler genellikle borular, armatürler, vanalar, su ısıtıcıları ve atık su yönlendirme sistemleri gibi birçok farklı bileşenden oluşur. Sıhhi tesisat, modern yaşamın vazgeçilmez bir parçasıdır ve binaların sağlıklı ve güvenli bir şekilde kullanılmasını sağlar.</p>",
        "serviceRichTextEn": "<p>Plumbing refers to the system of pipes, drains, fittings, valves, and fixtures installed in a building for the distribution of water for drinking, heating, and washing, and the removal of waterborne wastes. This system is crucial for ensuring the proper functioning and hygiene of any structure, be it residential, commercial, or industrial. Plumbing has become an indispensable part of modern living, allowing for the efficient use of water resources and the safe disposal of waste.</p>",
        "serviceRichTextRu": "<p>Водопровод относится к системе труб, канализации, фитингов, клапанов и сантехнических приспособлений, установленных в здании для распределения воды для питья, отопления и мытья, а также удаления сточных вод. Эта система крайне важна для обеспечения правильного функционирования и гигиены любой структуры, будь то жилой дом, коммерческое здание или промышленное предприятие. Водопровод стал неотъемлемой частью современной жизни, обеспечивая эффективное использование водных ресурсов и безопасное утилизацию отходов.</p>",
        "servicethumbnail": "/assets/sihhi.png"
    },
    {
        "id": "652aa708cf6e8dea9b696bcc",
        "serviceCaption": "DOĞALGAZ DAĞITIM SİSTEMLERİ",
        "serviceCaptionEn": "NATURAL GAS DISTRIBUTION SYSTEMS",
        "serviceCaptionRu": "СИСТЕМЫ РАСПРЕДЕЛЕНИЯ ПРИРОДНОГО ГАЗА",
        "serviceRichText": "<p>Bu sistemler genellikle doğalgaz kaynağından gelen ana boru hatları, basınç düzenleyiciler, sayaçlar ve dağıtım hatlarından oluşur. Bu sistemler, binaların içindeki gazla çalışan cihazlara, örneğin ocaklara, ısıtma sistemlerine veya su ısıtıcılarına doğalgaz sağlar. Bu sistemlerin güvenli bir şekilde kurulması ve düzenli bakımlarının yapılması, güvenlik açısından son derece önemlidir.\t</p>",
        "serviceRichTextEn": "<p>The natural gas distribution systems in buildings refer to the infrastructure that enables the supply of natural gas from its source to the points of consumption within the structures. These systems typically consist of main pipelines from the gas source, pressure regulators, meters, and distribution lines. They deliver natural gas to gas-operated appliances within buildings, such as stoves, heating systems, or water heaters. Proper installation and regular maintenance of these systems are crucial for ensuring safety.</p>",
        "serviceRichTextRu": "<p>Системы распределения природного газа в зданиях относятся к инфраструктуре, обеспечивающей подачу природного газа от его источника к точкам потребления внутри строений. Эти системы обычно состоят из основных трубопроводов от источника газа, регуляторов давления, счетчиков и линий распределения. Они обеспечивают подачу природного газа к газовым приборам внутри зданий, таким как плиты, системы отопления или водонагреватели. Правильная установка и регулярное техническое обслуживание этих систем крайне важны для обеспечения безопасности.</p>",
        "servicethumbnail": "/assets/gas.png"
    }
]

const ServicesPage = ({ location }) => {
    //const { loading, error, data } = useQuery(GET_DETAILSERVICES);
    const serviceLocation = location.replace("/services/", "");
    const { t } = useTranslation();
    const currentLanguage = i18next.language;

    // if (loading) return <Loading />
    // if (error) console.log(error)
    // const detailServicesData = data.getDetailServices

    return (
        <div className='container'>
            <div className='row'>
                <div className="col-md-3 col-md-pull-9">
                    <SidebarWidget data={servicesArray} translate={t} />
                </div>
                <div className="col-md-9 col-md-push-3 ">
                    {servicesArray.map((data, i) => (
                        <React.Fragment key={i}>
                            {serviceLocation === data.id && (
                                <div className="detail">
                                    <img src={data.servicethumbnail}
                                        alt={data.name} />
                                    <div>
                                        <p style={{ textAlign: "start" }} dangerouslySetInnerHTML={{ __html: currentLanguage !== "tr" ? currentLanguage === "en" ? data.serviceRichTextEn : data.serviceRichTextRu : data.serviceRichText }}>
                                        </p>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>

            </div>
        </div>
    )
}

export default ServicesPage