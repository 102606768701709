/* import { useQuery } from '@apollo/client'; */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
/* import { GET_MEDIA } from '../../graphql/query';
import Loading from '../../components/loading/Loading'; */
import { ImageList, ImageListItem, ListSubheader, Skeleton, ToggleButtonGroup } from '@mui/material';
import { medias } from '../../context/staticDatas';

const Media = () => {
    const { t } = useTranslation();
    // const { loading, error, data } = useQuery(GET_MEDIA);
    const [alignment, setAlignment] = React.useState('web');
    const [loading, setLoading] = useState(true);

    /*     if (loading) return <Loading />;
        if (error) console.log(error); */
    const windowCalculator = window.screen.width > 760 ? (window.screen.width < 1200 ? 2 : 3) : 1


    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };


    return (
        <div className='container' >
            <ToggleButtonGroup
                style={{ display: "flex" }}
                color='primary'
                exclusive
                value={alignment}
                onChange={handleChange}
                aria-label="Platform"
            >
            </ToggleButtonGroup>
            <ImageList className='pb-5 pt-1' style={{ gridTemplateColumns: `repeat(${windowCalculator}, 1fr)`, gap: "22px", overflowY: "visible" }}>
                <ImageListItem key="Subheader" cols={windowCalculator}>
                    <ListSubheader component="div" style={{ backgroundColor: "#393E46", color: "#EEEEEE", fontSize: "20px", fontWeight: "600", textTransform: "uppercase", borderRadius: "10px" }}>{t("media")}</ListSubheader>
                </ImageListItem>
                {medias.map((item) => (
                    <ImageListItem key={item.id} style={{ height: "200" }}>
                        <figure className="snip0060">
                      
                            <img
                                src={item.media}
                                srcSet={item.media}
                                alt={item.mediaTitle}
                                loading="lazy"
                                onLoad={() => setLoading(false)}
                            />
                            {item.mediaTitle && (
                                <figcaption>
                                    <div ><i style={{ backgroundColor: "transparent" }}></i></div>
                                    <div>
                                        <p>{item.mediaTitle}</p>
                                    </div>
                                </figcaption>
                            )}
                        </figure>

                        {/*               <ImageListItemBar
                            title={currentLanguage !== "tr" ? currentLanguage === "en" ? item.nameEn : item.nameRu : item.name}
                            subtitle={item.author} 
                            style={{ background: "rgba(0, 0, 0, 0.7)", fontWeight: "600", marginBottom: "16px" }}
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    aria-label={`info about ${item.mediaTitle}`}
                                    title={`${item.mediaTitle}`}
                                >
                                    <InfoIcon />
                                </IconButton>
                            }
                        /> */}
                    </ImageListItem>
                ))}
            </ImageList>
        </div >
    )
}

export default Media