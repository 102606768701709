import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import "./services.css"
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import isitma from "../../images/isitma.jpeg"
import havalandirma from "../../images/havalandirma.jpeg"
import tesisat from "../../images/tesisat.jpeg"
import yangin from "../../images/yangin.jpeg"

function Services() {
    const { t } = useTranslation();

    const image_sources = [
        {
            id: 1,
            image: isitma,
            title: "Isıtma & Soğutma Sistemleri",
            link: "services/65269adbbc7e86d175c91dfb"
        },
        {
            id: 2,
            image: havalandirma,
            title: "Havalandırma Sistemleri",
            link: "services/652a619233cce5095133369b"

        },
        {
            id: 3,
            image: tesisat,
            title: "Sıhhi Tesisat",
            link: "services/652aa53dcf6e8dea9b696b8c"

        },
        {
            id: 4,
            image: yangin,
            title: "Yangın Söndürme Sistemleri",
            link: "services/65269adbbc7e86d175c91dfb"

        }
    ]

    return (
        <Container className='servicesContainer'>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="serviceTitle">
                            <h3>{t('services')}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Row>
                {image_sources.map((img) => (
                    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12" key={img.id}>
                        <div className="hovereffect">
                            <img className="img-responsive" src={img.image} height={235} alt="" />
                            <div className="overlay">
                                <h2>{img.title}</h2>
                                <Link className="info" to={`/${img.link}`}>Devamını Okuyun</Link>
                            </div>
                        </div>
                    </div>
                ))}
            </Row>
        </Container>
    );
}

export default Services;