import "./home.css";
import Carousels from "../../components/carousels/Carousels"
import Services from "../../components/services/Services";
import FeaturedProjects from "../../components/featuredProjects/FeaturedProjects";
import React from "react";

function Home() {

  return (
    <div>
      <Carousels />
      <Services />
      <FeaturedProjects />
    </div>
  );
}

export default Home;