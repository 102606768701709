import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs, Zoom } from 'swiper/modules';

import "./projectPage.css";
import { BusinessCenter, DateRange, Engineering, Factory, LocationOn } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
/* import { useQuery } from '@apollo/client';
import { GET_PROJECT } from '../../graphql/query';
import Loading from '../../components/loading/Loading'; */
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { allProjectDetails } from '../../context/staticDatas';


const ProjectPage = () => {
    const { t } = useTranslation();

    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const { projectId } = useParams();

   /*  const { loading, error, data } = useQuery(GET_PROJECT, {
        variables: { ProjectID: projectId },
    }); */

    /*   if (loading) return <Loading />;
    if (error) console.log(error); */
    
    const currentProject = allProjectDetails[projectId]
    const currentLanguage = i18next.language;
    return (
        <div className='container CompletedProjectsContainer'>
            <div className='row mb-5'>
                <div className='col'>
                    <div className="contactHeader">
                        {currentLanguage !== "tr" ? currentLanguage === "en" ? currentProject.nameEn : currentProject.nameRu : currentProject.name}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4' style={{ marginTop: "16px" }}>
                    <div className='wpb_wrapper'>
                        <p>
                            <span style={{ fontSize: "14px", fontWeight: "500", color: "#303030" }}>{currentLanguage !== "tr" ? currentLanguage === "en" ? currentProject.detailEn : currentProject.detailRu : currentProject.detail}</span>
                        </p>
                    </div>
                    <div>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{ backgroundColor: "#393E46" }}>
                                        <LocationOn />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t("location")} secondary={currentLanguage !== "tr" ? currentLanguage === "en" ? currentProject.locationEn : currentProject.locationRu : currentProject.location} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{ backgroundColor: "#393E46" }}>
                                        <Factory />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t("relevantEntity")} secondary={currentLanguage !== "tr" ? currentLanguage === "en" ? currentProject.relevantEntityEn : currentProject.relevantEntityRu : currentProject.relevantEntity} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{ backgroundColor: "#393E46" }}>
                                        <Engineering />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t("projectScope")} secondary={currentLanguage !== "tr" ? currentLanguage === "en" ? currentProject.projectScopeEn : currentProject.projectScopeRu : currentProject.projectScope} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar >
                                    <Avatar style={{ backgroundColor: "#393E46" }}>
                                        <DateRange />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t("date")} secondary={currentProject.dateOfProject} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{ backgroundColor: "#393E46" }}>
                                        <BusinessCenter />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t("clientCompany")} secondary={currentLanguage !== "tr" ? currentLanguage === "en" ? currentProject.clientCompanyEn : currentProject.clientCompanyRu : currentProject.clientCompany} />
                            </ListItem>
                        </List>
                    </div>
                </div>
                <div className='col-md-8 CompletedProjectsImageGallery'  >
                    <Swiper
                        style={{
                            '--swiper-navigation-color': '#fff',
                            '--swiper-pagination-color': '#fff',
                        }}
                        zoom={true}
                        loop={true}
                        spaceBetween={10}
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                        modules={[FreeMode, Navigation, Thumbs, Zoom]}
                        className="mySwiper2"
                    >
                        {currentProject.images.map((item, i) => (
                            <SwiperSlide key={i}>
                                <div className='swiper-zoom-container'>
                                    <img
                                        src={item}
                                        alt='Ekinoks Mühendislik'
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        loop={true}
                        spaceBetween={10}
                        slidesPerView={5}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper"
                    >
                        {currentProject.images.map((item, i) => (
                            <SwiperSlide key={i}>
                                <img
                                    src={item}
                                    alt={currentProject.name}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div >
    )
}

export default ProjectPage